import { ChainId, SUPPORTED_CHAINS, SupportedChainsType } from './chains'

type AddressMap = { [chainId: number]: string }

type ChainAddresses = {
  factoryAddress: string
  multicallAddress: string
  quoterAddress: string
  orderBookAddress?: string
  nonfungiblePositionManagerAddress?: string
  tickLensAddress?: string
  swapRouterAddress?: string
  v1MixedRouteQuoterAddress?: string
  lockAddress?: string
  tradeRouter?: string
  voterAddress?: string
  rougexTokenAddress?: string
  faucetAddress?: string
  minterAddress?: string
  tradeReaderAddress?: string
  rewardsDistributorAddress?: string
  roxUtilsAddress?: string
  PosnReader?: string
}

const DEFAULT_NETWORKS = [ChainId.ARBITRUM_ONE]

function constructSameAddressMap(address: string, additionalNetworks: ChainId[] = []): AddressMap {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}

const ARBITRUM_ONE_ADDRESSES: ChainAddresses = {
  factoryAddress: '0x2140c5B4ef9Cc6d18196C43eeFA9dfF3595467A2',
  tradeRouter: '0xBCcDa22DB89AA35Eff735c433AE0f2642dd3Da07',
  nonfungiblePositionManagerAddress: '0x5Cf3679187B6096a990393bBE67Ec191e3fD7416',
  swapRouterAddress: '0x064d6286b4A9702a85e934AECac2407eb509F34a',
  quoterAddress: '0xA13f5A14c384F550c7168453c1EE0B30F10dc4d0',
  tickLensAddress: '0xE58E92B0A47F29c38A919527099B4d5f7eff6d43',

  roxUtilsAddress: '0x586536752bD0d3D767440E73FdBa2D4016990E2F',
  tradeReaderAddress: '0xE48f2C155e4Cc7D7d2aFBEEc11ce85088e7d5D90',
  orderBookAddress: '0x9D0DF48c859942cFd437F1A5e8e5f8980118e445',

  lockAddress: '0xCD3B42917b4d1f896F0735C1D6EDd44304548Fa4',

  rougexTokenAddress: '0x1F4D8C5f5cb85E923Bff7D73bf08795E68d4b648',
  rewardsDistributorAddress: '0x0d0E982AE77DE7bcd59f9Bd9bf19BBa6d2068964',

  voterAddress: '0x03534749642F4545233CCFDA3c1074fDc8a5511e',
  minterAddress: '0xF73e7422fBBdB4CB4252D0eF3724D9eb6967Aca3',

  multicallAddress: '0x027EDCF231957833e95Bf6131E61EA8345d580d3',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

// eslint-disable-next-line multiline-comment-style
// arbitrum goerli v3 addresses
const GOERLI_ADDRESSES: ChainAddresses = {
  factoryAddress: '0x0149Ae85D16f396A8cc34445876a304097910639',
  tradeRouter: '0xe32A514D929Daa0A9b3853C71bD0e22d80679CBA',
  nonfungiblePositionManagerAddress: '0xDC3e6cA428F36EEfBb6eE2c40AD2E84FDEe2Db19',
  swapRouterAddress: '0x63238B8e910288f6aB3C8Eebef207d0C7b12b66e',
  quoterAddress: '0x4082541102D020f5aC0a9677E2d7dcAa0BcCB631',
  tickLensAddress: '0x6f91826dAb2aFFD602A97EFeAc8Ba99FA9226062',
  roxUtilsAddress: '0xb92C858f8208C82bE209d347b378BF7d63eBb15F',
  tradeReaderAddress: '0xD4f02851F730e8Ad23560C8d02236E7904885D43',
  orderBookAddress: '0xd95E11E8288F94D5E7eF14DB3f7a7F4D85b5b6fD',

  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',

  lockAddress: '0x47Cd8AAae6AaDa375AB712bD2340aBD05d403566',
  rougexTokenAddress: '0x3d3fADCbeba5e68a35581681b6a2D650DAd292f4',
  rewardsDistributorAddress: '0x7F80E24A4C482584605c592908fe3278732F5838',

  voterAddress: '0x86395AB62F130E77D1CF237C6d6EBa8F6A7DDc3d',
  minterAddress: '0x55DF366DAb41B8207c7060b9ae67D257bB9A655D',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

const Sepolia_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xa55F2744DA15cD95822E8e0958305C58e32F9a98',
  tradeRouter: '0xE84594F5B8740E428405f6928C493ef63A318dbA',
  nonfungiblePositionManagerAddress: '0x0Fe7ACf5a758144536e415540e560650B79F6214',
  swapRouterAddress: '0xf8D61E10845893c12B9C495e01976C43F957b529',
  quoterAddress: '0x86eFaA010f65B17dD7504fA7450d56aEcC77bcAD',
  tickLensAddress: '0x4Eb761FCdEC79825b50d7572AdC02601b453639b',
  roxUtilsAddress: '0x75B801637DBB14E59B6Bf378b558601B7B219057',
  tradeReaderAddress: '0xF18F2234823130C50EeD4736893a328Ab4ab54D0',
  orderBookAddress: '0x06422f49ab551c6D7e1ad30af4B7A80f5712d13d',

  multicallAddress: '0x532a5B1A2F8ccE4f05F8b2a9A262c7097B4356ec',

  lockAddress: '0x48878653673113bD4DbEd485a34E194735BA7E10',
  rougexTokenAddress: '0x00fA700a16eF7B3c968Bb1dCD4C6bA697bA73fb9',
  rewardsDistributorAddress: '0x20B7ab1AD1517Bd17cD2F4dc5C29920Bff92ba82',

  voterAddress: '0x780A9Fa3B434b99A2f7751B00c6fC269f19871Bd',
  minterAddress: '0x918891120A62B0F2a91012a8De9f10Dc30a9E3Cd',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

const BLAST_TEST_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xD9D07283566cDf97437faD2eeeE7F7Ca95dc799f',
  tradeRouter: '0xF8FDfb0074aE187e48aC55332750dCF825a2a046',
  nonfungiblePositionManagerAddress: '0x3B13e91AB27383aBf239bfA1bE4F69c663D54161',
  swapRouterAddress: '0x9f0359492A8dAfb9C5bfbE3246fD874817A9b7ba',
  quoterAddress: '0x3A292939e8151C3CB7A85c738Ee0497dce8ecfbC',
  tickLensAddress: '0x5e62b3aCAa4d892e8696e2dddf573648207Db4B5',
  roxUtilsAddress: '0x2B2A893BdF764C1B0F06D2C59ECDBf7291b10d1C',
  tradeReaderAddress: '0x6209b245D1378B59dEb361BA0132DEAc81007aB6',
  orderBookAddress: '0x53D6aBE3B585E6b491D354b8098f6b1611Aa7F52',

  multicallAddress: '0xB2f67eA43943e6C14580B9ECc88B3b05969eD4b3',

  lockAddress: '0xbD0c3e5cc7aBc6033f9E7C96d603278A0D5929A2',
  rougexTokenAddress: '0x91Da16c662ff307dE7A80C4D8B95b1fA866e50af',
  rewardsDistributorAddress: '0x76A8d84aBeCFf68a53a7014763BE9623990b64E5',

  voterAddress: '0xE3Ddfc574fA73ae369541c1303ae2Fce8AB292ee',
  minterAddress: '0x6E61230570F1600403aaD50b6a72ec0f1a53C031',
  PosnReader: '0xf4bF098950Fb8F603203B78714fA0F8B2A7041b9',
  faucetAddress: '0x2516F4Cb4De50CC62B8705d4067C42b451b5A36c',
}
/*
    "RoguexFactory" : "0xbd9215e002E4344c8333fc0bf4F5ECEd62BF9B85",
    "RoxUtils" : "0xc9bCbF1Bb96c5b19513aF64464dEa8C69c790369",
    "PerpUtils" : "0x88c8464C11193F2E2D4618521B935949D4Dc8749",
    "NonfungiblePositionManager" : "0x8632A0b0095f6c6D51A03E245D93B25eD2b6F709",
    "Quoter" : "0x88332d6e6aD87e2C72BF04c04a5057947D438885",
    "TickLens" : "0xf0ca239Cbb24F33Fb92A9dB1D47e62feD6cDa8a4",
    "PerpRouter" : "0x090787f79911b19C8DbF1261Abe4FF5b4B833Bd7",
    "SwapRouter" : "0xc8CBb74fc79F85d1Df0aB89D5a7b6766D237EbfC",
    "PerpOrderbook" : "0x18aa46961E952812C62B9Ffc9aA7780AAAdb3826",
    "VeArtProxy" : "0xA005714099390813079F9Fb3E7EFcdc6A36E7e80",
    "VotingEscrow" : "0x717aA1606F1891F9Dc124381a9ba16B51FFCF396",
    "Voter" : "0x21F758179a1e40007234B6f3B0688d135864Caf8",
    "Minter" : "0xd702783F90e4573B2906b558B625b53ceDD72542",
    "DisFeeReward" : "0x946D5dF1C585C0fb8a96191B00847E47d76598E3",
    "HypervisorFactory" : "0xf0C2F7199CA0CdD073D6D2cC1A0F888D02aFC62E",
    "Reader" : "0xb81B69DC539eD055d0547aAb96dA7D1BaeC4D7Ad",
    "PosnReader" : "0xBb731A4d6C563e8A48871479511930F98C37e6d4",
*/
const BLAST_MAIN_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xbd9215e002E4344c8333fc0bf4F5ECEd62BF9B85',
  tradeRouter: '0x090787f79911b19C8DbF1261Abe4FF5b4B833Bd7',
  nonfungiblePositionManagerAddress: '0x8632A0b0095f6c6D51A03E245D93B25eD2b6F709',
  swapRouterAddress: '0xc8CBb74fc79F85d1Df0aB89D5a7b6766D237EbfC',
  quoterAddress: '0x88332d6e6aD87e2C72BF04c04a5057947D438885',
  tickLensAddress: '0xf0ca239Cbb24F33Fb92A9dB1D47e62feD6cDa8a4',
  roxUtilsAddress: '0xc9bCbF1Bb96c5b19513aF64464dEa8C69c790369',
  tradeReaderAddress: '0xb81B69DC539eD055d0547aAb96dA7D1BaeC4D7Ad',
  PosnReader: '0xBb731A4d6C563e8A48871479511930F98C37e6d4',
  orderBookAddress: '0x18aa46961E952812C62B9Ffc9aA7780AAAdb3826',

  multicallAddress: '0xdf119e932877E61e5C2DB627C70130D37E45814C',

  lockAddress: '0x717aA1606F1891F9Dc124381a9ba16B51FFCF396',
  rougexTokenAddress: '0x93E069A379b1Db74C10635893AbeC755CB8fD62e',
  rewardsDistributorAddress: '0xD4D589602441eCd9833CB1e05e542474D4c95512',

  voterAddress: '0x21F758179a1e40007234B6f3B0688d135864Caf8',
  minterAddress: '0xd702783F90e4573B2906b558B625b53ceDD72542',

  faucetAddress: '0x2516F4Cb4De50CC62B8705d4067C42b451b5A36c',
}

const CHAIN_TO_ADDRESSES_MAP: Record<SupportedChainsType, ChainAddresses> = {
  [ChainId.ARBITRUM_ONE]: ARBITRUM_ONE_ADDRESSES,
  [ChainId.GOERLI]: GOERLI_ADDRESSES,
  [ChainId.ARBITRUM_SEPOLIA]: Sepolia_ADDRESSES,
  [ChainId.BLAST_TEST]: BLAST_TEST_ADDRESSES,
  [ChainId.BLAST_MAIN]: BLAST_MAIN_ADDRESSES,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].factoryAddress
    return memo
  }, {}),
}

export const MULTICALL_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].multicallAddress
    return memo
  }, {}),
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}

export const QUOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].quoterAddress
    return memo
  }, {}),
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const nonfungiblePositionManagerAddress = CHAIN_TO_ADDRESSES_MAP[chainId].nonfungiblePositionManagerAddress
    if (nonfungiblePositionManagerAddress) {
      memo[chainId] = nonfungiblePositionManagerAddress
    }
    return memo
  }, {}),
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'),
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tickLensAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tickLensAddress
    if (tickLensAddress) {
      memo[chainId] = tickLensAddress
    }
    return memo
  }, {}),
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const swapRouter02Address = CHAIN_TO_ADDRESSES_MAP[chainId].swapRouterAddress
    if (swapRouter02Address) {
      memo[chainId] = swapRouter02Address
    }
    return memo
  }, {}),
}
export const TRADE_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeRouter = CHAIN_TO_ADDRESSES_MAP[chainId].tradeRouter
    if (tradeRouter) {
      memo[chainId] = tradeRouter
    }
    return memo
  }, {}),
}

export const LOCK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const lockAddress = CHAIN_TO_ADDRESSES_MAP[chainId].lockAddress
    if (lockAddress) {
      memo[chainId] = lockAddress
    }
    return memo
  }, {}),
}

export const VOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const voterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].voterAddress
    if (voterAddress) {
      memo[chainId] = voterAddress
    }
    return memo
  }, {}),
}

export const MINTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const minterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].minterAddress
    if (minterAddress) {
      memo[chainId] = minterAddress
    }
    return memo
  }, {}),
}

export const ROUGEX_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rougexTokenAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rougexTokenAddress
    if (rougexTokenAddress) {
      memo[chainId] = rougexTokenAddress
    }
    return memo
  }, {}),
}

export const FAUCET_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const faucetAddress = CHAIN_TO_ADDRESSES_MAP[chainId].faucetAddress
    if (faucetAddress) {
      memo[chainId] = faucetAddress
    }
    return memo
  }, {}),
}
export const TRADEREADER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeReaderAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tradeReaderAddress
    if (tradeReaderAddress) {
      memo[chainId] = tradeReaderAddress
    }
    return memo
  }, {}),
}

export const REWARDS_DISTRIBUTOR_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rewardsDistributorAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rewardsDistributorAddress
    if (rewardsDistributorAddress) {
      memo[chainId] = rewardsDistributorAddress
    }
    return memo
  }, {}),
}

export const ROX_UTILS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const roxUtilsAddress = CHAIN_TO_ADDRESSES_MAP[chainId].roxUtilsAddress
    if (roxUtilsAddress) {
      memo[chainId] = roxUtilsAddress
    }
    return memo
  }, {}),
}

export const ORDER_BOOK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].orderBookAddress
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}

export const POSN_READER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].PosnReader
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}
