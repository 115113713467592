import { Box, useMediaQuery } from '@mui/material'
import { ReactComponent as AirdropIcon } from 'assets/imgs/airdrop.svg'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'

const StyledPtsBtnBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 30px;
  border-radius: 15px;
  background: ${({ theme }) => theme.blast};
`

export default function StyledAirdropM() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)

  return (
    <StyledPtsBtnBox>
      <AirdropIcon />
    </StyledPtsBtnBox>
  )
}
